import React from "react";
import classNames from "classnames";
import css from './Header.module.css'

const Header: React.FC<any> = ({...props}: any) => {

    return (
        <div className={classNames(css.header, css.see_through)}>
            <h1>Sideline Safety</h1>
            <p>
                Dedicated to the accurate and timely communication of pertinent information to athletes' families and friends
            </p>
        </div>
    )
}

export default Header;