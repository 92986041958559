import React from 'react'
import Header from './Header';
import css from './Landing.module.css'
import Content from "./Content";

const Landing: React.FC<any> = ({...props}: any) => {

    return (
        <div className={css.background}>
            <Header />
            <Content />
        </div>
    )
}

export default Landing
