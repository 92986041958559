import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import css from './Content.module.css';
import {Card, CardContent} from "@material-ui/core";
import Profile from "./Profile";

import toddLogo from './images/Todd.jpg';
import ericLogo from './images/Eric.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardroot: {
        minWidth: 275,
    },
}));

const Content: React.FC<any> = ({...props}: any) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.root, css.content)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography>
                                We are a group of software professionals, and athletic coaches and players with family members also involved in various types of sports.
                                We want to provide clear and timely communication from the sideline to family and loved-ones in the event of a sporting incident.
                                We believe that when family and friends are properly informed, they can make the best decisions for their loved ones in case of an injury.
                                We also believe the athletic leadership can lead to the best of their ability when they have the appropriate information about those they are responsible for.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Profile
                        name={"Todd Ford"}
                        email={"todd.ford@sidelinesafety.com"}
                        image={toddLogo}
                        message={`
                        I grew up playing sports from the age of 5. 
                        Started in wrestling then added baseball, football and even some track and field at some points of my youth. 
                        I have had many injury's and a lot of time trying to remember what happen and how it happen to tell the Doctor or nurse got tough. 
                        Now that i am a coach and A father I find myself wanted help in this situation for taking care of my player and my Kids. 
                        That is where the Incident track idea came to life.
                        `}
                    />
                </Grid>
                <Grid item md={6}>
                    <Profile
                        name={"Eric Freeman"}
                        email={"eric.freeman@sidelinesafety.com"}
                        image={ericLogo}
                        message={`
                        Having the opportunity to experience sports during my childhood, I wanted to be able to give future youth the same opportunity. 
                        Having worked in the software industry for many years, I found a perfect harmony to apply my experience in software to fulfill a vision of 
                        helping future youth also enjoy such a blessing and pastime that I had. It is important that coaches and staff are fully enabled to better 
                        communicate with everyone to allow youth to pursue sports while being able to properly respond in cases of injuries and emergencies; 
                        our goal is to provide that enablement that sports teams need.
                        `}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Content;