import React from 'react'
import Landing from "../Landing";

import css from './Container.module.css';

const Container: React.FC<any> = ({...props}: any) => {


    return (
        <div className={css.background}>
            <Landing />
        </div>
    );
}

export default Container;