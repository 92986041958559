import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 300,
        },
        media: {
            width: 40,
            height: 40
        },
    }),
);

const Profile: React.FC<any> = ({name, email, image, message, ...props}: any) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader
                title={name}
                subheader={email && (
                    <a href={`mailto:${email}`}>{email}</a>
                )}
                avatar={<Avatar>
                    <CardMedia
                        className={classes.media}
                        image={image}
                    />
                </Avatar>}
            />

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {message}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default Profile;